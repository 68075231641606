import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerTeam from '../components/BannerTeam'

import daiwik from '../assets/images/team/daiwik.jpeg'
import anisha from '../assets/images/team/anisha.jpg'
import shivaani from '../assets/images/team/shivaani.jpeg'
import harsha from '../assets/images/team/harsha.jpg'
import sid from '../assets/images/team/sid.jpeg'
import anishaJr from '../assets/images/team/anishaJr.jpeg'
import kaushik from '../assets/images/team/kaushik.jpeg'
import aum from '../assets/images/team/aum.jpeg'
import vinay from '../assets/images/team/vinay.jpeg'
import snigdha from '../assets/images/team/snigdha.jpeg'
import anirudh from '../assets/images/team/anirudh.jpeg'
import sohan from '../assets/images/team/sohan.jpeg'
import nishant from '../assets/images/team/nishant.jpeg'

const Team = () => (
  <Layout>
    <Helmet>
      <title>Krypton Warriors</title>
      <meta name="description" content="Landing Page" />
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "53ca0e93ef80463192e735fa104fef39"}'></script>n
    </Helmet>

    <BannerTeam />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>About</h2>
          </header>
          <p>
            We are the Krypton Warriors team from Parsippany, New Jersey. Our
            team consists of 13 members. This is our sixth year of participating
            in the <em>FIRST</em> Tech Challenge (FTC) competition. We participated in
            FLL for the three years before that. We all came together as a team because
            of our love for science, technology, engineering and Math. 
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link className="image">
            <img src={anisha} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Anisha Mulinti</h3>
              </header>
              <p>
              Hi! My name is Anisha Mulinti and I am in 11th grade at Montville Township High School. 
              This is my 8th year with the FIRST organization as I have previously competed in Jr FLL, 
              FLL, and now FTC. I am one of the Co-Captains on the team. I am also a coder and have learned a 
              lot of skills and lessons throughout robotics that I use in my everyday life. 
              Outside of FTC, I enjoy playing tennis and going to the beach.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img src={harsha} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Harsha Tumu</h3>
              </header>
              <p>
              Hi, my name is Harsha Tumu and I am in 11th grade at Morris Hills High School, Academy of Math Science and Engineering. This is my second year in FTC and with the Krypton Warriors. I am one of the Co-Captains and a builder on the team. I have also been a driver. Programming also interests me. Besides robotics, some of my hobbies are playing video games, taking apart things, and cooking.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img src={aum} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Aum Shah</h3>
              </header>
              <p>
              Hi, my name is Aum Shah and I am in 11th grade at the Academy for Mathematics, Science and Engineering. This is my second year in FTC with the Krypton Warriors. Throughout my FTC experience I have learned many valuable skills including programming, teamwork, and collaboration. Outside of Krypton Warriors, I love math, physics, and engineering.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img 
            src={daiwik} 
            alt="" 
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Daiwik Namala</h3>
              </header>
              <p>
              Hi, my name is Daiwik Namala and I am in 10th grade at Parsippany
              Hills High School. This is my second year in FTC and with the Krypton
              Warriors. I am the coach and builder on the team, and I also work on outreaches
              and connects. Besides robotics, some of my hobbies are playing video games,
              cook, programming PCs and listening to music on Spotify.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
            src={kaushik}
            alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Kaushik Panyala</h3>
              </header>
              <p>
              Hi, my name is Kaushik Panyala and I am in 10th grade at Morris Hills High School. This is my second year in FTC and with Krypton Warriors and prior to FTC, I also participated in FLL as well. I love coding and I am the programmer on the team. I also help with organizing outreaches and connects. Besides robotics, I love to play guitar, tennis and chess.  My hobbies include, playing video games, reading books, and listening to music.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={shivaani}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Shivaani Sangar</h3>
              </header>
              <p>
              Hello, My name is Shivaani Sangar, and I am a 9th-grade student at Parsippany Hills High School. As a passionate learner, I am excited to embark on a new adventure and participate in the world of robotics. I am thrilled to be part of the FTC team in my second year. I bring my unique set of skills to the team as one of its programmers, but I also like to get involved in connects and outreach activities. Aside from robotics, I also enjoy participating in cross country and dance. In my free time, I love spending time with my friends, sharing my love of cooking, and cultivating my garden. Overall, I'm grateful for all the opportunities that come my way and look forward to continuing to pursue my passions.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={sid}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sid Tumu</h3>
              </header>
              <p>
              Hi, my name is Sid Tumu and I am in 9th grade at Morris Hills High School. This is my second year in FTC and with the Krypton Warriors. I am a builder on the team and a driver. Besides robotics, some of my hobbies are playing racketball, video editing, and listening to music.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={anishaJr}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Anisha Senthilkumaran</h3>
              </header>
              <p>
              Hello, my name is Anisha Senthilkumaran and I am in 7th grade at Randolph Middle School. This is my second year in FTC and the Krpton Warriors team, but prior to that, i have done junior FLL. I am mainly a CADer and work in 3D printing on this team. I have learned lots of skills participating that I can use in my everyday life. Outside of the Krypton Warriors extra curricular things I like to do are, playing violin, piano, tennis, building things, and classical dance.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={snigdha}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Snigdha Kethireddy</h3>
              </header>
              <p>
              Hello, My name is Snigdha Kethireddy, and I am a 7th-grade student at Brooklawn Middle School. As a enthusiastic learner, I am excited to participate in robotics and begin a new path. I am very grateful and exited to be a part of FTC. I am a coder on this team and I always try to do my best. Aside from robotics, I also enjoy participating in tennis and swimming. In my free time, I like spending time with my friends, expressing my love for art and cooking. 
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={anirudh}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Anirudh Raghunath</h3>
              </header>
              <p>
              Hello, my name is Anirudh Raghunath, commonly known as Ani. I am in 10th grade at Parsippany Hills High School. This is my first year participating in both FTC and with the Krypton Warriors. I am both a builder and a programmer on the team. Outside of FTC, some of my hobbies are playing soccer, playing video games, chess, martial arts, and listening to music on Spotify and Youtube Music.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={sohan}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sohan Pai</h3>
              </header>
              <p>
              Hi, my name is Sohan Pai and I am a sophomore at Parsippany Hills High School. This is my first year with the Krypton Warriors and First as a whole. I am a builder on the team who occasionally does CAD. Some of my other hobbies include playing soccer and video games with friends, spending time with family, listening to music, and watching movies. The FTC experience has taught me a lot not only about technical robotics concepts such as the Engineering Design Process, but it has also allowed me to refine my soft skills in areas like collaboration.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={vinay}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Vinay Bonu</h3>
              </header>
              <p>
              Hello. My name is Vinay and I am an eighth grader from Brooklawn Middle School. I am a builder and one of the drivers. This is my first year in FTC. 
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link className="image">
            <img
              src={nishant}
              alt=""
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Nishant Das</h3>
              </header>
              <p>
              Hi my name is Nishant Das, and I am in 9th grade. I go to Parsippany Hills High School. This is my first year as part of the Krypton Warriors, and I am a builder who also led outreaches for the team. Some of my hobbies are Tennis, chess, and piano.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Team
